import { useState, useRef, useEffect, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import * as actionTypes from "../../store/actions";
import { useLocation } from "react-router-dom";
import LoginUserDataContext from "../../store/login";
import { Formik, Form } from "formik";
import * as YUP from "yup";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import Table from "../../components/Table/rowExpansionTable";
import styles from "./logSummary.module.scss";
import * as constants from "../../constants/constants";
import { logSummaryColumns, autoLogSummaryColumns } from "../../constants/table-columns/tableColumns";
import { logSummaryHeader } from "../../components/Table/table-header/tableHeader"
import { rowExpansionLogSummaryColumns,rowExpansionLogSummaryColumnsErrors } from "../../constants/table-columns/tableColumns";
import {logSummaryApi, reProcessApi} from "../../services/logSummary";
import downloadLogSummary from "../../services/downloadlogsummary";
import Spinner from "../../components/spinner/spinner";
import { Toast } from "primereact/toast";
import { sortData } from "../../constants/dataSort";
import { Dialog } from "primereact/dialog";
import generateXML from "../../services/generateXML";
import FeatureFlagDataContext from "../../store/featureFlags";
import { appInsights } from '../../AppInsights';
const initialValue = {
  batchId: "",
  region: null,
  function: null,
  customer: "",
  ponum:null,
  startdate: new Date(),
  enddate: new Date()
};

const LogSummaryList = (props) => {
  const toast = useRef(null);
  const formRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [featureFlags, setFeatureFlags] = useContext(FeatureFlagDataContext);
  const heading = location?.pathname.toLocaleLowerCase();
  // const batchId = queryParams.get('batchId');
  const [isBatchIdAvailable, setBatchIdAvailable] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [showProceedConfirmation, setShowProceedConfirmation] = useState(false);
  const [batchId, setBatchId] = useState(queryParams.get("batchId"));
  const [xmlFunctionId, setXMLFunctionId] = useState(queryParams.get("functionId"));
  const [isLogSummary, setIsLogSummary] = useState(true);
  const [filteredStatus, setFilterredStatus] = useState({label:"--Select--", id:5});
  const [reprocessRecordsdata, setReprocessRecordsData] = useState(null);
  const [logSummaryListData, setLogSummaryListData] = useState([]);
  const [logSummaryListDataCopy, setLogSummaryListDataCopy] = useState([]);
  const [autoLogSummaryListData, setAutoLogSummaryListData] = useState([])
  const [autoLogSummaryListDataCopy, setAutoLogSummaryListDataCopy] = useState([])
  const [showSpinner, setShowSpinner] = useState(false);
  const [userData, setUserData] = useContext(LoginUserDataContext);
  const errorPosition = "center";

  const [logSummaryApiInput, setlogSummaryApiInput] = useState("");
  const dispatch = useDispatch();
  const { getRegions, getFunctions, getAllFunctionsForLogSummary } =
    bindActionCreators(actionCreators, dispatch);
  const regionList = useSelector((state) => {
    const data = state.orderReducer.regions;
    const sortedData = sortData("regionNameAbbr", data);
    return sortedData;
  });
  const statusFilterOptions =[
    {label:"--Select--", id:5},
    {label:"Pending", id:1},
    {label:"Processed", id:2},
    {label:"Failed", id:3},
    {label:"InProcess", id:4}
  ]
  useEffect(()=>{
    if(heading.includes('autolog')){
      setIsLogSummary(false)
      setDisableFields(false)
    }
    else{
      setIsLogSummary(true)
      setDisableFields(false)
    }
    formRef.current.resetForm();
    setAutoLogSummaryListData([]);
    setLogSummaryListData([])
    setAutoLogSummaryListDataCopy([]);
    setLogSummaryListDataCopy([]);
    setFilterredStatus({label:"--Select--", id:5})
  },[heading])
  useEffect(() => {
    if (batchId) {
      formRef?.current?.setFieldValue("batchId", batchId);
      setBatchIdAvailable(true);
      setDisableFields(true);
      fetchLogSummaryData(formRef?.current?.values);
      formRef?.current?.setFieldValue("startdate",null);
      formRef?.current?.setFieldValue("enddate",null);
    }
  }, [batchId]);
  const functionList = useSelector((state) => {
    if (formRef?.current?.values?.region?.regionId) {
      const data = state.orderReducer?.functions?.[
        formRef?.current?.values?.region?.regionId
      ]
        ? state.orderReducer?.functions?.[
            formRef?.current?.values?.region?.regionId
          ]
        : [];
      const sortedData = sortData("functionName", data);
      return sortedData;
    }
    return [];
  });
  const functionListAll = useSelector((state) => {
    const data = state.orderReducer?.allfunctionsForLogSummary
      ? state.orderReducer?.allfunctionsForLogSummary
      : [];
    const sortedData = sortData("functionName", data);
    return sortedData;
  });
  useEffect(() => {
    if(heading.includes('autolog')){
      const params = {
        statusId: 41,
        isLogSummary: false,
        isAutoLogSummary: true
      };
      getAllFunctionsForLogSummary({ params });
      getRegions();
    }
    else{
      const params = {
        statusId: 41,
        isLogSummary: true,
        isAutoLogSummary: false
      };
      getAllFunctionsForLogSummary({ params });
      getRegions();
    }
   
  }, [heading]);

  useEffect(() => {
     if(xmlFunctionId){
      const data = functionListAll.filter((fn) =>{
        if(fn?.functionId == xmlFunctionId){
          return fn;
        }
      } )
      if(data?.length > 0){
        formRef?.current?.setFieldValue("function", data[0]);
        fetchLogSummaryData(formRef?.current?.values);
      }  
    }
  }, [xmlFunctionId,functionListAll]);
  useEffect(() => {
    if (regionList.length > 0 && regionList[0] === "error") {
      setShowSpinner(false);
      dispatch({
        type: actionTypes.GET_REGIONS,
        value: []
      });
    }
    else if(regionList.length > 0){
      defaultRegionSelection()
    }
  }, [regionList, heading]);

  const defaultRegionSelection = () =>{
    if(userData?.regionId != null){
      let filteredRegion = regionList?.filter((item)=> item?.regionId == userData?.regionId)
      formRef?.current?.setFieldValue("region", filteredRegion[0]);
      handleRegionChange( filteredRegion[0]);
    }
    else{
      formRef?.current?.setFieldValue("region", regionList[0]);
      handleRegionChange( regionList[0]);
    }
  }

  const onStatusFilter = (event) => { 
      setFilterredStatus(event.value)
      const selectedStatus = event.value.label;
      if(isLogSummary){
        if(event?.value?.id != 5){
        const filteredData = logSummaryListDataCopy.map((item) => {
          return {
            ...item,
            logSummaryFileDTOs: item.logSummaryFileDTOs.filter(record => record.status === selectedStatus)
          };
        });
        const data = filteredData?.filter((item) => item?.logSummaryFileDTOs?.length >0)
        setLogSummaryListData(data);
      }
      else{
        ClearStatusFilter()
      }
      }
      else{
        if(event?.value?.id != 5){
        const filteredData = autoLogSummaryListDataCopy.map((item) => {
          return {
            ...item,
            logSummaryFileDTOs: item.logSummaryFileDTOs.filter(record => record.status === selectedStatus)
          };
        });
        const data = filteredData?.filter((item) => item?.logSummaryFileDTOs?.length >0)
        setAutoLogSummaryListData(data);
      }
      else{
        ClearStatusFilter()
      }
      }
  }
  const ClearStatusFilter = () =>{
    setFilterredStatus({label:"--Select--", id:5});
    setAutoLogSummaryListData(autoLogSummaryListDataCopy)
    setLogSummaryListData(logSummaryListDataCopy)
  }
  const fetchFunctions = async (regionId) => {
    try {
      const params = {
        regionId: regionId,
        statusId: 41,
        isLogSummary: isLogSummary?true:false,
        isAutoLogSummary: isLogSummary?false:true,
      };
      formRef?.current?.setFieldValue("function", null);
      getFunctions({ params });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception whilefetching functions in logsummary screen",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };
  const handleRegionChange = (e) => {
    let regionId = e?.target?.value?.regionId ? e?.target?.value?.regionId : e?.regionId
    if (regionId) {
      fetchFunctions(regionId);
    }
  };


  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const commonValidation = YUP.mixed().nullable();
  const validationSchema = YUP.object().shape({
    startdate: !isBatchIdAvailable
      ? YUP.date().required("Start date is required")
      : YUP.mixed().nullable(),
    enddate: !isBatchIdAvailable
      ? YUP.date().required("End date is required")
      : YUP.mixed().nullable()
  });

  const fetchLogSummaryData = async (formbody) => {
    try {
      setFilterredStatus({label:"--Select--", id:5})
      setShowSpinner(true);
      const formData = {
        regionId: formbody?.region?.regionId,
        functionId: formbody?.function?.functionId
          ? formbody?.function?.functionId
          : xmlFunctionId? Number(xmlFunctionId) :  null,
        customerId: formbody?.customer ? formbody?.customer : null,
        poNumber: formbody?.ponum ? formbody?.ponum : null,
        startDate: formbody?.startdate ? formatDate(formbody?.startdate) : null,
        endDate: formbody?.enddate ? formatDate(formbody?.enddate) : null,
        isAutoLogSummary: !heading.includes('autolog') ? false : true,
        batchId: formbody?.batchId
          ? formbody?.batchId
          : batchId
          ? batchId
          : null
      };
      setlogSummaryApiInput(formData);
      let result = "";
      result = await logSummaryApi(formData);
      result = result?.map((p, index) => {
        p.id = `id${index}`;
        return p;
      });
      setLogSummaryListData(result);
      setLogSummaryListDataCopy(result);
      setAutoLogSummaryListData(result);
      setAutoLogSummaryListDataCopy(result);
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      setLogSummaryListData([]);
      setLogSummaryListDataCopy([]);
      setAutoLogSummaryListData([]);
      setAutoLogSummaryListDataCopy([]);
     if (error?.message !== "No records found") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 4000
        });
      }
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while submitting logsummary screen",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };
  const handleBatchChange = (event) => {
    const { value } = event.target;
    if (value != "" && value != undefined && value != null) {
      formRef?.current?.setFieldValue("batchId", value);
      setBatchIdAvailable(true);
    } else {
      formRef?.current?.setFieldValue("batchId", null);
      setBatchIdAvailable(false);
    }
  };
  const fetchLogSummary = async () => {
    try {
      if (logSummaryApiInput && logSummaryListData.length) {
        // let result = "";
        downloadLogSummary(logSummaryApiInput);

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: result,
        //   life: 3000
        // });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000
      });
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while downloading logsummary",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };


  const reProcessItem = async (data) => {
    try {
      setShowSpinner(true);
      let result = "";
      result = await reProcessApi(data?.orderXMLFileNameId);
      if (result == "File reprocessed successfully") {
        fetchLogSummaryData(formRef?.current?.values);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: result,
          life: 4000
        });
      }
      setShowSpinner(false);
    } catch (error) {
      setShowSpinner(false);
      if (error?.message !== "No records found") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 4000
        });
      }
      appInsights.trackException({
        exception: error,
        properties: {
          additionalInfo: "Exception while reprocess data",
        },
        measurements: {
          severityLevel: 2 
        }
      });
    }
  };

  const proceed = async (data) => {
    setShowProceedConfirmation(true);
    setReprocessRecordsData(data);
  };

  const handleReprocess = async () =>{
    
    try{
      setShowProceedConfirmation(false);
      setShowSpinner(true);
      let result = "";
       result = await generateXML(reprocessRecordsdata?.lineItems, true);
      if(result){
        fetchLogSummaryData(formRef?.current?.values);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail:"Successfully generated XML for sucess records.",
          life: 4000
        });
      }
      setShowSpinner(false);
    }
    catch (error) {
      setShowSpinner(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.message,
          life: 4000
        });
        appInsights.trackException({
          exception: error,
          properties: {
            additionalInfo: "Exception while proceed button click",
          },
          measurements: {
            severityLevel: 2 
          }
        });
    }
  }

  
  const footerContent = (
    <div style={{ float: "left" }}>
      <Button
        label="OK"
        type="button"
        size="large"
        onClick={handleReprocess}
        autoFocus
      />
      <Button
        label="Cancel"
        type="submit"
        size="large"
        onClick={() => setShowProceedConfirmation(false)}
        className={` ${styles.tirnaryBtn}`}
      />
    </div>
  );

  return (
    <>
      {showSpinner && <Spinner />}

      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setShowSpinner(true);
          fetchLogSummaryData(values);
        }}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit} ref={formRef}>
            <div className="grid">
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="batchId">
                    <span className={styles.formLabel}>Batch Id</span>
                  </label>
                </div>

                <span className="p-input-icon-left">
                  <InputText
                    id="batchId"
                    value={values?.batchId}
                    onChange={handleBatchChange}
                    onBlur={handleBlur}
                    className={`${styles.inputField} ${styles.formControl}`}
                  />
                </span>
              </div>
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Region</span>
                  </label>
                </div>
                <Dropdown
                  id="region"
                  filter
                  placeholder="Select a Region"
                  value={values?.region}
                  options={regionList}
                  disabled={disableFields}
                  optionLabel="regionNameAbbr"
                  onChange={(e) => {
                    handleChange(e);
                    handleRegionChange(e);
                  }}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />
              </div>
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Function</span>
                  </label>
                </div>
                <Dropdown
                  id="function"
                  filter
                  placeholder="Select a Function"
                  value={values?.function}
                  disabled={disableFields}
                  options={
                    formRef?.current?.values?.region
                      ? functionList
                      : functionListAll
                  }
                  optionLabel="functionName"
                  onChange={handleChange}
                  className={`${styles.dropdown} ${styles.formControl}`}
                />

                <div>
                  {errors.function && touched.function && (
                    <span className={styles?.errorMsg}>{errors.function}</span>
                  )}
                </div>
              </div>
              {isLogSummary &&<div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Cust Id</span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="customer"
                    placeholder="Cust Id"
                    value={values?.customer}
                    onChange={handleChange}
                    // disabled={disableFields}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
                {/* <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    id="customer"
                    value={values?.customer}
                    onChange={handleChange}
                    className={`${styles.inputField} ${styles.formControl}`}
                  />
                </span> */}
              </div>}
              {!isLogSummary && <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="ponum">
                    <span className={styles.formLabel}>PO Contains</span>
                  </label>
                </div>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                  </span>
                  <InputText
                    id="ponum"
                    placeholder="PO Number"
                    value={values?.ponum}
                    onChange={handleChange}
                    // disabled={disableFields}
                    className={`${styles.inputField} ${styles.formControl} ${styles.noBorder}`}
                  />
                </div>
              </div>}
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}>
                  <label htmlFor="region">
                    <span className={styles.formLabel}>Date Range</span>
                    {!isBatchIdAvailable && <span className="star">*</span>}
                  </label>
                </div>
                <Calendar
                  id="startdate"
                  value={values?.startdate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="Start Date"
                  maxDate={formRef?.current?.values?.enddate}
                />
                <div>
                  {errors.startdate && touched.startdate && (
                    <span className={styles?.errorMsg}>{errors.startdate}</span>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-2">
                <div className={styles.formLabelContainer}></div>
                <Calendar
                  id="enddate"
                  value={values?.enddate}
                  onChange={handleChange}
                  disabled={disableFields}
                  placeholder="End Date"
                  minDate={formRef?.current?.values?.startdate}
                />
                <div>
                  {errors.enddate && touched.enddate && (
                    <span className={styles?.errorMsg}>{errors.enddate}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="col-12 md:col-4 md:col-offset-8">
                <div className="grid" style={{ alignItems: "center" }}>
                  <div
                    className="col-4 col-offset-1"
                    style={{ textAlign: "right" }}
                  >
                    {/* <div className={styles.formLabelContainer}></div> */}
                    <Button
                      label="Search"
                      type="submit"
                      size="medium"
                      className={styles.primaryBtn}
                    />
                  </div>
                  <div className="col-4" style={{ paddingLeft: "0px" }}>
                    {/* <div className={styles.formLabelContainer}></div> */}
                    <Button
                      label="Reset"
                      type="button"
                      size="medium"
                      onClick={(e) => {
                        // formRef?.current?.setFieldValue("region", null);
                        defaultRegionSelection()
                        setLogSummaryListData([]);
                        setLogSummaryListDataCopy([]);
                        setAutoLogSummaryListData([]);
                        setAutoLogSummaryListDataCopy([]);
                        setBatchIdAvailable(false);
                        setBatchId(null);
                        setXMLFunctionId(null);
                        setDisableFields(false);
                      }}
                      className={styles.resetBtn}
                    />
                  </div>
                  <div className="col-3" style={{ textAlign: "end" }}>
                    <i
                      className={`${styles.downloadIcon} pi pi-download ${
                        logSummaryApiInput && logSummaryListData.length
                          ? styles.enableDownloadIcon
                          : ""
                      } `}
                      onClick={() => {
                        fetchLogSummary();
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="grid">
              <div className="col-11 grid">
                <div className="col-10 grid">
                  <div className="col-12 md:col-2">
                    <div className={styles.formLabelContainer}>
                      <label htmlFor="region">
                        <span className={styles.formLabel}>Region</span>
                      </label>
                    </div>
                    <Dropdown
                      id="region"
                      filter
                      placeholder="Select a Region"
                      value={values?.region}
                      options={regionList}
                      optionLabel="regionNameAbbr"
                      onChange={(e) => {
                        handleChange(e);
                        handleRegionChange(e);
                      }}
                      className={`${styles.dropdown} ${styles.formControl}`}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <div className={styles.formLabelContainer}>
                      <label htmlFor="region">
                        <span className={styles.formLabel}>Function</span>
                        <span className="star">*</span>
                      </label>
                    </div>
                    <Dropdown
                      id="function"
                      filter
                      placeholder="Select a Function"
                      value={values?.function}
                      options={
                        formRef?.current?.values?.region
                          ? functionList
                          : functionListAll
                      }
                      optionLabel="functionName"
                      onChange={handleChange}
                      className={`${styles.dropdown} ${styles.formControl}`}
                    />

                    <div>
                      {errors.function && touched.function && (
                        <span className={styles?.errorMsg}>
                          {errors.function}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-2">
                    <div className={styles.formLabelContainer}>
                      <label htmlFor="region">
                        <span className={styles.formLabel}>Cust Id</span>
                      </label>
                    </div>

                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        id="customer"
                        value={values?.customer}
                        onChange={handleChange}
                        className={`${styles.inputField} ${styles.formControl}`}
                      />
                    </span>
                  </div>
                  <div className="col-12 md:col-2">
                    <div className={styles.formLabelContainer}>
                      <label htmlFor="region">
                        <span className={styles.formLabel}>Date Range</span>
                        <span className="star">*</span>
                      </label>
                    </div>
                    <Calendar
                      id="startdate"
                      value={values?.startdate}
                      onChange={handleChange}
                      placeholder="Start Date"
                      maxDate={formRef?.current?.values?.enddate}
                    />
                    <div>
                      {errors.startdate && touched.startdate && (
                        <span className={styles?.errorMsg}>
                          {errors.startdate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-2">
                    <div className={styles.formLabelContainer}></div>
                    <Calendar
                      id="enddate"
                      value={values?.enddate}
                      onChange={handleChange}
                      placeholder="End Date"
                      minDate={formRef?.current?.values?.startdate}
                    />
                    <div>
                      {errors.enddate && touched.enddate && (
                        <span className={styles?.errorMsg}>
                          {errors.enddate}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-1">
                    <div className={styles.formLabelContainer}></div>
                    <Button
                      label="Search"
                      type="submit"
                      size="medium"
                      className={styles.primaryBtn}
                    />
                  </div>
                </div>
                <div className={`col-12 md:col-2`}>
                  <div className={styles.formLabelContainer}></div>
                  <Button
                    label="Reset"
                    type="button"
                    size="medium"
                    onClick={(e) => {
                      formRef?.current?.setFieldValue("region", null);
                      formRef.current.resetForm();
                      setLogSummaryListData([]);
                    }}
                    className={styles.resetBtn}
                  />
                </div>
              </div>
              <div className="col-1 grid">
                <div className="col-12 md:col-12" style={{ textAlign: "end" }}>
                  <i
                    className={`${styles.downloadIcon} pi pi-download ${
                      logSummaryApiInput && logSummaryListData.length
                        ? styles.enableDownloadIcon
                        : ""
                    } `}
                    onClick={() => {
                      fetchLogSummary();
                    }}
                  ></i>
                </div>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>

      <Table
        content={isLogSummary? logSummaryListData : autoLogSummaryListData}
        columnsData={isLogSummary? logSummaryColumns  : autoLogSummaryColumns}
        rowExpansionColumnsData={rowExpansionLogSummaryColumns(isLogSummary? "showLog"  : "showAuto",reProcessItem, proceed, featureFlags?.enableReprocessFiles)}
        rowExpansionLogSummaryColumnsErrorsData={isLogSummary? null : rowExpansionLogSummaryColumnsErrors}
        rows={constants.defaultRows}
        rowsPerPageOptions={constants.rowsPerPageOptions}
        paginator={constants.paginator}
        scrollable={constants.scrollable}
        emptyMessage={constants.emptyMessage}
        tableStyle={constants.tableStyle}
        currentPageReportTemplate={constants.currentPageReportTemplate}
        paginatorTemplate={constants.paginatorTemplate}
        isLogSummary={isLogSummary}
        isCopyEnable={false}
        styles={styles}
        header={()=>logSummaryHeader({styles, statusFilterOptions, onStatusFilter,filteredStatus, ClearStatusFilter})}
      />
      <Toast ref={toast} position="center" />
      {showProceedConfirmation && (
        <Dialog
          header="Confirmation"
          visible={showProceedConfirmation}
          style={{ width: "50vw" }}
          className="xmlListDialog"
          footer={footerContent}
          onHide={() => setShowProceedConfirmation(false)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="pi pi-exclamation-triangle px-2"
              style={{ fontSize: "2rem", color: "#F97316" }}
            />
            <p>
              Error records will not be processed anymore. Are you sure you want
              to proceed with success records?
            </p>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default LogSummaryList;
